<template>
  <div class="layout-config h-100 p-20">
    <Row :gutter="10">
      <Col span="6">
        <h3>账户信息</h3>
        <div class="m-t-20 user-info">
          <span>账号：</span><span>{{ user.userId }}</span>
        </div>
        <div class="m-t-10 user-info">
          <span>所在组织：</span
          ><span>{{
            user.organization && user.organization.organizationName
          }}</span>
        </div>
        <Button type="warning" class="m-t-10" @click="isEdit = !isEdit"
          >修改密码</Button
        >
      </Col>
      <Col span="18">
        <Divider orientation="left" class="m-t-20 f-20">快速登录</Divider>
        <Card class="card-item">
          <div class="f-18">
            <Icon
              class="m-r-5 weChat-icon"
              custom="iconfont icon-weixin"
              :size="24"
            />微信登录
          </div>

          <div class="m-t-20 text-r">
            <Button
              type="success"
              class="f-16"
              @click="bindWeChat"
              v-if="!weChatState"
              >绑定微信</Button
            >
            <Button
              type="error"
              class="f-16 m-l-5"
              @click="delBind"
              v-if="weChatState"
              >解绑微信登录</Button
            >
          </div>
        </Card>
        <div class="m-t-20">
          <iframe
            :src="iframeUrl"
            frameborder="0"
            width="400"
            height="500"
            v-if="isBind"
          ></iframe>
        </div>
      </Col>
    </Row>
    <Modal v-model="isEdit" footer-hide>
      <div class="f-16 m-b-30">修改密码</div>
      <Form
        ref="form"
        :model="formItem"
        :rules="rules"
        :label-width="80"
        hide-required-mark
        label-colon
        class="w-90"
      >
        <FormItem label="旧密码" prop="oldPassword">
          <Input
            v-model="formItem.oldPassword"
            type="password"
            name="old-password"
            autocomplete="new-password"
            placeholder="请输入旧密码"
            clearable
          />
        </FormItem>
        <FormItem label="新密码" prop="newPassword">
          <Row type="flex" justify="center" align="middle">
            <Col span="22">
              <Input
                ref="input"
                v-model="formItem.newPassword"
                name="new-password"
                :type="isPassword ? 'password' : 'text'"
                placeholder="请输入新密码"
                autocomplete="new-password"
                clearable
              />
            </Col>
            <Col span="2" class="text-c">
              <Icon
                size="20"
                type="md-eye"
                class="c-h h-blue m-t-5"
                @click="isPassword = !isPassword"
                v-if="isPassword"
              />
              <Icon
                size="20"
                type="md-eye-off"
                class="c-h h-blue m-t-5"
                @click="isPassword = !isPassword"
                v-else
              />
            </Col>
          </Row>
        </FormItem>
      </Form>
      <div class="text-r m-t-20">
        <Button type="text" @click="isEdit = !isEdit">取消</Button>
        <Button type="warning" @click="editPassword">修改密码</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { config } from "@/config";
export default {
  data() {
    return {
      code: null,
      state: null,
      iframeUrl: "",
      isBind: false,
      isEdit: false,
      isPassword: true,
      weChatState: null,
      formItem: {},
      rules: {
        oldPassword: { required: true, message: "请输入旧密码！" },
        newPassword: {
          required: true,
          pattern: config.pattern.pass,
          message: "密码长度为6-22位内的数字、字母或者特殊字符！",
        },
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    // 密码验证
    /** 
     * checking() {
      console.log(this.password)
      if(this.password) {
        console.log(config.pattern.pass.test(this.password))
        if(!config.pattern.pass.test(this.password)) {
          this.rule = "密码格式错误,密码长度为6-22位内的数字、字母或者特殊字符"
        } else {
          this.rule = null
        }
      } else {
        this.rule = "密码不能为空，请输入要修改的密码！"
        this.$refs.input.focus({
          cursor: 'start'
        });
      }
    }, */

    // 修改密码
    editPassword() {
      if (!this.formItem.oldPassword) {
        this.$Message.warning("请先输入旧密码！");
        return;
      }
      if (!this.formItem.newPassword) {
        this.$Message.warning("请先输入要修改的密码！");
        this.$refs.input.focus({
          cursor: "start",
        });
        return;
      }
      this.$store
        .dispatch("user/changePassword", {
          user_id: this.user.userId,
          ...this.formItem,
        })
        .then((res) => {
          if (res.code == "HA0200") {
            this.$Message.success("密码修改成功！");
            this.isEdit = !this.isEdit;
          }
        });
    },
    // 微信登录绑定
    bindWeChat() {
      this.$store.dispatch("user/getWxState").then((res) => {
        if (res.code == "HA0200") {
          this.isBind = !this.isBind;
          let url = encodeURIComponent(
            `http://web.xingyaedu.cn/?redirect_uri=${
              location.href
            }/&token_authserver=${localStorage.getItem("token_authserver")}`
          );
          this.iframeUrl = `https://open.weixin.qq.com/connect/qrconnect?appid=wxcc2bfb36f5d889b8&redirect_uri=${url}&response_type=code&scope=snsapi_login&state=${res.data}#wechat_redirect`;
        }
      });
    },
    // 解除微信登录绑定
    delBind() {
      this.$store.dispatch("user/delBindWeChat").then((res) => {
        if (res.code == "HA0200") {
          this.$Message.success("微信解绑成功！");
        } else {
          this.$Message.success(res.msg);
        }
      });
    },
    async getWxInfo() {
      const { data } = await this.$store.dispatch("user/getWxInfo");
      this.weChatState = data;
    },
    getData() {
      this.$store.dispatch("user/getUserInfoId");
    },
  },
  async mounted() {
    this.isBind = false;
    this.getWxInfo();
    if (!this.user.userId) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.layout-config {
  .user-info {
    span:nth-child(1) {
      display: inline-block;
      width: 90px;
    }
  }
  .card-item {
    width: 420px;
    .weChat-icon {
      color: rgb(40, 196, 69);
    }
  }
}
</style>
